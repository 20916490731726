import React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Head from '../components/Head'
import Img from "gatsby-image"

export const query = graphql`
  query {
    file(relativePath: { eq: "pages/family.jpg" }) {
      childImageSharp {
        fixed(width: 600) {
          src
          aspectRatio
          base64
          srcSet
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <div className="page-section container content">
        <Head title="About" />
        <h1 className="has-text-danger">About</h1>
        <div class="columns">
          <div class="column">
            <p>I am a front end software developer. I love coding in <Link to="/consulting/ember">ember.js</Link>, react.js, node, javascript, ruby, rails, and the usual HTML/CSS.</p>
            <p>When I'm not coding, I enjoy collecting vinyl records (jazz, funk & soul), drinking craft coffee & wine, cooking, biking, hiking, playing ping pong & basketball, and spending time with my family. I am a proud father of 2 boys</p>
            <h2>Favorite Quotes</h2>
            <blockquote>
              Would you like me to give you a formula for success? It's quite simply, really. Double your rate of failure. <br />
              <p className="has-text-grey	has-text-right">- Thomas J Watson, founder of IBM</p>
            </blockquote>
            <blockquote>
              What's <span style={{textDecoration: "underline", fontWeight: "bold"}}>the ONE Thing</span> I can do such that by doing it everything else will be easier or unnecessary?
              <p className="has-text-grey	has-text-right">- Gary Keller, author of <span className="is-italic">The One Thing</span></p>
            </blockquote>
            <blockquote>Focus on the process (the way you spend your time) instead of the product (what you want to accomplish).</blockquote>
            <blockquote>What we fear doing most is usually why we most need to do.</blockquote>
          </div>
          <div class="column">
            <Img
              fluid={data.file.childImageSharp.fixed}
              alt="Me and my family"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
